.deployment-list-item {
    border-radius: 5px;
    margin-top: 0;
    cursor: pointer;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);;
}

.deployment-list-item.is-success {
    border-color: hsl(141, 71%, 48%);
    background-color: hsl(142, 52%, 96%);
    border-width: 1px;
    border-style: solid;
}

.deployment-list-item.is-failed {
    border-color: hsl(348, 100%, 61%);
    background-color: hsl(347, 90%, 96%);
    border-width: 1px;
    border-style: solid;
}