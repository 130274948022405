.deployment-step.is-success {
    border-color: hsl(141, 71%, 48%);
    background-color: hsl(142, 52%, 96%);
    border-width: 1px;
    border-style: solid;
}

.deployment-step.is-failed {
    border-color: hsl(348, 100%, 61%);
    background-color: hsl(347, 90%, 96%);
    border-width: 1px;
    border-style: solid;
}